export default [  
  {
    familyID: "7306",
    location: "Boulder, CO",
    startDate: "ASAP",
    schedule: "Thursdays 3-7PM, 2 adtl days/wk 3-6:30PM",
    hours: "~11 hours, options for date nights/weekends",
    pay: "$30+/hour",
    placementLength: "School year",
    nKids: "2 children, 4 and 2 years",
    familydescription:
      "Family of 4 + dog ",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Jun 20, 2024"
  }
];
